/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"

import Header from "../Header"
import Sidebar from "../Sidebar"
import Menu from "../Menu"

/* Styles */
import "./styles.scss"

const Layout = ({ children, bgImage }) => {
  const [menuOpenState, setMenuOpenState] = useState(false)

  const openMenu = e => {
    e.preventDefault()
    setMenuOpenState(true)
  }

  const closeMenu = e => {
    // e.preventDefault()
    setMenuOpenState(false)
  }

  const stateChangeHandler = newState => {
    setMenuOpenState(newState.isOpen)
  }

  return (
    <BackgroundImage
      id="background-img-container"
      Tag="section"
      fluid={bgImage}
      backgroundColor={`#000`}
    >
      <Sidebar
        right
        width={280}
        isOpen={menuOpenState}
        onStateChange={stateChangeHandler}
        customBurgerIcon={false}
        outerContainerId={"background-img-container"}
        pageWrapId={"page-wrap"}
        handleCloseMenu={closeMenu}
      />
      <div id="page-wrap" className="d-flex w-100">
        <div className="d-flex w-100">
          <Container fluid className="main px-0">
            <Row noGutters className="justify-content-center">
              <Col xs={12}>
                <Header openMenu={openMenu} />
              </Col>
              <Col xs={12}>
                <main>{children}</main>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="desktop-menu-wrap d-lg-block d-none">
          <Menu />
        </div>
      </div>
    </BackgroundImage>
  )
}

export default Layout
