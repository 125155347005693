import React from "react"
import { Link } from "gatsby"
import { ListGroup } from "react-bootstrap"

/* Styles */
import "./styles.scss"

const { Item } = ListGroup

const Menu = ({ handleCloseMenu = () => {} }) => {
  return (
    <ListGroup
      className="main-menu align-items-end bg-transparent border-0 text-right"
      variant="flush"
    >
      <Item className="bg-black border-0 mb-2 pr-5">
        <Link
          className="text-decoration-none text-white"
          to="/"
          activeClassName="active"
          onClick={handleCloseMenu}
        >
          HOME
        </Link>
      </Item>
      <Item className="bg-black border-0 mb-2 pr-5">
        <Link
          className="text-decoration-none text-white"
          to="/the-hybrid"
          activeClassName="active"
          onClick={handleCloseMenu}
          partiallyActive
        >
          THE HYBRID
        </Link>
      </Item>
      <Item className="bg-black border-0 mb-2 pr-5">
        <Link
          className="text-decoration-none text-white"
          to="/contacto"
          activeClassName="active"
          onClick={handleCloseMenu}
        >
          CONTACTO
        </Link>
      </Item>
    </ListGroup>
  )
}

export default Menu
