import { graphql, Link, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import { RiMenu3Line } from "react-icons/ri"
import { Col, Container, Row } from "react-bootstrap"

/* Styles */
import "./styles.scss"

const Header = ({ openMenu }) => {
  const { logoImg } = useStaticQuery(graphql`
    query {
      logoImg: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 238, height: 55) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logo = logoImg.childImageSharp.fixed

  return (
    <header className="header d-flex align-items-center align-items-lg-end justify-content-between bg-transparent position-relative">
      <Container>
        <Row>
          <Col>
            <div className="d-flex align-items-center justify-content-between">
              <Link
                to="/"
                style={{
                  color: `transparent`,
                  textDecoration: `none`,
                }}
              >
                <Image fixed={logo} />
              </Link>
              <a className="d-lg-none" href="!#" onClick={openMenu}>
                <RiMenu3Line color="#fff" size="2rem" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
