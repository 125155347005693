import React from "react"
import { scaleDown as Menu } from "react-burger-menu"
import NavMenu from "../Menu"
import { CgClose } from "react-icons/cg"

/* Styles */
import "./styles.scss"

const Sidebar = props => {

  const {handleCloseMenu} = props

  return (
    <Menu {...props} customCrossIcon={<CgClose />}>
      <NavMenu handleCloseMenu={handleCloseMenu} />
    </Menu>
  )
}

export default Sidebar
